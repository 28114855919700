var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "relative flex max-w-xs items-center justify-center border-r",
    },
    [
      _c(
        "div",
        { staticClass: "max-h-full" },
        _vm._l(_vm.navDefinitions, function (componentHash, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "block",
              on: {
                click: function ($event) {
                  return _vm.setActiveClass(
                    componentHash.routerLinkActive,
                    index
                  )
                },
              },
            },
            [
              _c(
                "nav-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.getValueFromDefinitionKey(
                        componentHash,
                        "isDisplayed"
                      ),
                      expression:
                        "getValueFromDefinitionKey(componentHash, 'isDisplayed')",
                    },
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.right",
                      value: { content: _vm.getToolTip(componentHash) },
                      expression: "{ content: getToolTip(componentHash) }",
                      modifiers: { right: true },
                    },
                  ],
                  class: [
                    componentHash.htmlClasses,
                    "py-4",
                    _vm.activeClass && _vm.selectedIndex === index
                      ? "router-link-active"
                      : "",
                  ],
                  attrs: {
                    color: "dark",
                    "data-testid": componentHash.icon,
                    exact: componentHash.isExact,
                    to: componentHash.toSlug
                      ? { name: componentHash.toSlug }
                      : null,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.callClickFunction(componentHash)
                    },
                  },
                },
                [
                  componentHash.icon
                    ? _c("fv-icon", {
                        attrs: { icon: componentHash.icon, size: "lg" },
                      })
                    : _vm._e(),
                  _vm.isMessageNotificationDisplayed(componentHash)
                    ? _c("span", {
                        staticClass:
                          "-mt-1 h-3 w-3 animate-pulse rounded-full bg-primary",
                      })
                    : _vm._e(),
                  componentHash.isPingVisible
                    ? _c("div", { staticClass: "relative" }, [
                        _c("span", {
                          staticClass:
                            "absolute right-0 top-0 h-2 w-2 animate-ping rounded-full bg-primary",
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass:
            "absolute bottom-0 mb-1 text-center text-xs text-gray-600",
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.platform))]),
          _c("p", [_vm._v("v" + _vm._s(_vm.appVersion))]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }