interface NavButtonDefinition {
  htmlClasses: string | null;
  tooltip: string;
  isExact: boolean;
  isPingVisible: boolean;
  toSlug: string | null;
  isDisplayed: string | boolean;
  onClick: string | null;
  icon?: string | null;
}

const navButtonDefs: NavButtonDefinition[] = [
  {
    htmlClasses: 'w-full',
    tooltip:
      'Vetpanel has been updated. Click here to refresh the browser window and download the latest version.',
    isExact: false,
    toSlug: null,
    isPingVisible: true,
    onClick: 'refreshWindow',
    isDisplayed: 'isUpdateDisplayed',
    icon: 'rocket',
  },
  {
    htmlClasses: null,
    tooltip: 'Home / dashboard',
    toSlug: 'dashboard',
    isExact: true,
    isPingVisible: false,
    onClick: null,
    isDisplayed: true,
    icon: 'home',
  },
  {
    htmlClasses: null,
    tooltip: 'Network & audio/video testing',
    toSlug: 'network-diagnosis',
    isExact: false,
    isPingVisible: false,
    onClick: null,
    isDisplayed: true,
    icon: 'dashboard',
  },
  {
    htmlClasses: null,
    tooltip: 'My calendar',
    toSlug: 'calendar',
    isExact: false,
    isPingVisible: false,
    onClick: null,
    isDisplayed: true,
    icon: 'calendar',
  },

  {
    htmlClasses: null,
    tooltip: 'Previous consultations',
    toSlug: 'appointments',
    isExact: false,
    isPingVisible: false,
    onClick: null,
    isDisplayed: true,
    icon: 'consultations',
  },

  // {
  //   htmlClasses: null,
  //   tooltip: 'Chat room',
  //   toSlug: 'chat-room',
  //   isExact: true,
  //   isPingVisible: false,
  //   onClick: null,
  //   isDisplayed: 'isChatDisplayed',
  //   icon: 'chat',
  // },

  {
    htmlClasses: window.location.href.includes('message')
      ? 'router-link-active'
      : null,
    tooltip: 'My messages',
    toSlug: 'messages',
    isExact: false,
    isPingVisible: false,
    onClick: null,
    isDisplayed: true,
    icon: 'email',
  },

  {
    htmlClasses: null,
    tooltip: 'Questions',
    toSlug: 'questions',
    isExact: false,
    isPingVisible: false,
    onClick: null,
    isDisplayed: 'isMedicalQuestionsDisplayed',
    icon: 'question',
  },
  {
    htmlClasses: 'w-full',
    tooltip: 'Toggle timeline',
    toSlug: null,
    isExact: false,
    isPingVisible: false,
    onClick: 'toggleTimeline',
    isDisplayed: true,
    icon: 'chevron-left',
  },
];

// NOTE: Currently does nothing. When roles are implemented,
//		 we can stop them being displayed by filtering them out
//		 here, or supplying some function name to check for role authentication
//		 to the defintions "isDisplayed" value...
const filteredNavButtonDefs: NavButtonDefinition[] = navButtonDefs;

export default filteredNavButtonDefs;
