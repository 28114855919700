<template>
  <div class="relative flex max-w-xs items-center justify-center border-r">
    <div class="max-h-full">
      <!-- ===== start nav bar buttons ==== -->
      <div
        v-for="(componentHash, index) of navDefinitions"
        :key="index"
        class="block"
        @click="setActiveClass(componentHash.routerLinkActive, index)"
      >
        <nav-button
          v-show="getValueFromDefinitionKey(componentHash, 'isDisplayed')"
          v-tooltip.right="{ content: getToolTip(componentHash) }"
          color="dark"
          :data-testid="componentHash.icon"
          :class="[
            componentHash.htmlClasses,
            'py-4',
            activeClass && selectedIndex === index ? 'router-link-active' : '',
          ]"
          :exact="componentHash.isExact"
          :to="componentHash.toSlug ? { name: componentHash.toSlug } : null"
          @click="callClickFunction(componentHash)"
        >
          <fv-icon
            v-if="componentHash.icon"
            :icon="componentHash.icon"
            size="lg"
          />
          <span
            v-if="isMessageNotificationDisplayed(componentHash)"
            class="-mt-1 h-3 w-3 animate-pulse rounded-full bg-primary"
          />
          <div v-if="componentHash.isPingVisible" class="relative">
            <span
              class="absolute right-0 top-0 h-2 w-2 animate-ping rounded-full bg-primary"
            />
          </div>
        </nav-button>
      </div>
      <!-- ===== end nav bar buttons ==== -->
    </div>
    <div class="absolute bottom-0 mb-1 text-center text-xs text-gray-600">
      <p>{{ platform }}</p>
      <p>v{{ appVersion }}</p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import NavButton from '@/UI/buttons/NavButton.vue';
import navDefinitions from '@/config/left-navbar-buttons';

import { FvIcon } from '@/UI/icons';
import { UK_COUNTRY_ID, US_COUNTRY_ID } from '@/config/countries';
import {
  getValueFromDefinitionKey,
  callClickFunction,
} from '@/config/definitionUtils';
import { authComputed } from '@/store/auth-helpers';

export default {
  components: {
    NavButton,
    FvIcon,
  },
  data() {
    return {
      navDefinitions,
      activeClass: '',
      selectedIndex: null,
    };
  },
  computed: {
    ...authComputed,
    ...mapState(['showTimeline', 'appVersion', 'hasLatestVersion', 'platform']),
    ...mapState('support', ['hasNewSupportMessages']),
    ...mapGetters('auth', ['isAdmin', 'isVet']),
    ...mapState('user', ['user']),
    isMedicalQuestionsDisplayed() {
      return this.$can('manage', 'Questions');
    },
    isClinicMapAndChartBarDisplayed() {
      return this.isAdmin || this.isVet;
    },
    isScheduleDashboardDisplayed() {
      return this.isAdmin;
    },
    isUpdateDisplayed() {
      return !this.hasLatestVersion;
    },
    isChatDisplayed() {
      return (
        this.user.country_id === UK_COUNTRY_ID ||
        this.user.country_id === US_COUNTRY_ID
      );
    },
  },
  methods: {
    ...mapMutations(['toggleTimeline']),
    setActiveClass(item, index) {
      this.activeClass = item && this.$router.currentRoute.path.includes(item);
      this.selectedIndex = index;
    },
    callClickFunction(...args) {
      return callClickFunction.call(this, ...args);
    },
    getValueFromDefinitionKey(...args) {
      const componentHash = args[0];
      const propertyKey = args[1];
      const isShowTimeLineButton = componentHash.onClick === 'toggleTimeline';
      const isToolTipOrFontIcon =
        propertyKey === 'tooltip' || propertyKey === 'icon';

      if (isShowTimeLineButton && isToolTipOrFontIcon) {
        const caretLeft = 1;
        const caretRight = 0;
        const iconOptions = componentHash[propertyKey].split('/');
        return this.showTimeline
          ? iconOptions[caretRight]
          : iconOptions[caretLeft];
      }

      return getValueFromDefinitionKey.call(this, ...args);
    },

    isMessageNotificationDisplayed(componentHash) {
      const isMessageButton = componentHash.toSlug === 'messages';
      return (
        isMessageButton &&
        this.hasNewSupportMessages &&
        this.$route.name !== 'messages'
      );
    },

    getFontAwesomeIconName(componentHash) {
      return this.getValueFromDefinitionKey(componentHash, 'fontIcon');
    },

    getToolTip(componentHash) {
      return this.getValueFromDefinitionKey(componentHash, 'tooltip');
    },

    refreshWindow() {
      window.location.reload(true);
    },
  },
};
</script>
<style lang="postcss" scoped>
.router-link-active {
  @apply bg-beigeLight text-primary-darkest !important;
}
</style>
