<template>
  <div v-if="user" id="vetPanel" class="flex h-screen flex-col">
    <!-- Top navbar -->
    <div class="flex w-full grow bg-brown">
      <TopNavbar />
    </div>

    <!-- Left navbar -->
    <div class="flex h-screen flex-row">
      <div
        v-if="$can('manage', 'Veterinarian') || $can('manage', 'All')"
        class="hidden flex-row bg-white bg-opacity-75 md:flex"
      >
        <LeftNavbar />
      </div>

      <div
        v-if="$can('manage', 'Veterinarian')"
        v-show="showTimeline"
        class="z-50 mt-10 hidden w-32 bg-white md:flex"
        data-testid="navbar-timeline"
      >
        <Timeline />
      </div>

      <!-- Content container -->
      <div class="w-full flex-1 justify-center overflow-auto">
        <router-view :key="$route.fullPath" class="overflow-auto" />
      </div>
      <!--

        -->
    </div>

    <service-notification-modal
      :data="remoteConfigs['service_information_message']"
      :show="showServiceModal"
      :dismissible="
        remoteConfigs['service_information_message'] &&
        remoteConfigs['service_information_message'].dismissible
      "
      @close="showServiceModal = false"
    >
      <!-- <div v-if="remoteConfigs['service_information_message']">
        <div class="mb-3 text-lg">
          {{ remoteConfigs['service_information_message'].title }}
        </div>
        <p>{{ remoteConfigs['service_information_message'].message }}</p>
      </div> -->
    </service-notification-modal>

    <SupportTicketModal
      v-if="showSupportTicket && $can('manage', 'Veterinarian')"
      :id="$router.currentRoute.params.id || ''"
      :show="showSupportTicket"
      @close="toggleSupportTicketModal(false)"
    />
  </div>
</template>

<script>
import { SupportApi } from '@/api/index';
import LeftNavbar from '@/components/interface/LeftNavbar.vue';
import TopNavbar from '@/components/interface/TopNavbar.vue';
import SupportTicketModal from '@/components/support/SupportTicketModal.vue';
import Timeline from '@/components/timeline/Timeline.vue';
import { SV_COUNTRY_ID } from '@/config/countries';
import Echo from '@/plugins/echo';
import ServiceNotificationModal from '@/UI/modals/service-notification-modal.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    TopNavbar,
    LeftNavbar,
    Timeline,
    SupportTicketModal,
    ServiceNotificationModal,
  },

  data() {
    return {
      showServiceModal: false,
    };
  },

  computed: {
    ...mapState(['platform']),
    ...mapState(['showTimeline', 'showSupportTicket']),
    ...mapState('user', ['user']),
    ...mapState('firebase/remoteConfig', ['remoteConfigs']),
    ...mapGetters('support', {
      ticketList: 'getTicketList',
    }),
    ...mapGetters('auth', ['isAdmin', 'isVet']),
    isSwedishPlatform() {
      return localStorage.getItem('platform') === 'sv';
    },
  },
  watch: {
    user(val) {
      if (val) {
        this.listenToAnswerChannel();
      }
    },
  },
  mounted() {
    if (this.$axios.defaults.baseURL.includes('sv')) {
      this.setPlatform('sv');
    } else {
      this.setPlatform('intl');
    }
    const AUTH_TOKEN = localStorage.getItem('token');
    // Set auth for echo instance after we have a token (used to be set in echo.ts)
    Echo.options.auth.headers.Authorization = `Bearer ${AUTH_TOKEN}`;

    if (this.user?.id) {
      this.listenToAnswerChannel(this.user.id);
      this.setVet(this.user.id);
      this.getTickets();
    }
    if (this.isAdmin) {
      this.listenToTicketChannel();
      this.listenToAnswerChannel();
    }
    if (this.isVet) {
      Echo.channel('vetpanel.all').listen('.new.update', () => {
        this.setHasLatestVersion(false);
      });
    }

    this.fetchRemoteConfig('service_information_message');

    setTimeout(() => {
      let serviceMessage = this.remoteConfigs['service_information_message'];
      if (serviceMessage && Object.keys(serviceMessage).length !== 0) {
        this.showServiceModal = true;
      }
    }, 5000); // Show Service modal after 5 seconds.
  },

  methods: {
    ...mapMutations([
      'toggleSupportTicketModal',
      'setPlatform',
      'setHasLatestVersion',
    ]),
    ...mapMutations('support', [
      'setVet',
      'addTicket',
      'hydrateTicketList',
      'setHasNewSupportMessages',
      'addNewAnswer',
    ]),
    ...mapActions('support', ['fetchTickets', 'fetchTicket']),
    ...mapActions('firebase/remoteConfig', ['fetchRemoteConfig']),
    listenToAnswerChannel(id) {
      const channel = id ? `answer.${id}` : 'answer';
      Echo.private(channel).listen('.new.supportAnswer', e => {
        if (id && e.answer.user_id !== this.user.id) {
          this.$notify({
            group: 'primary',
            title: 'Success!',
            text: `New message from ${e.answer.user.display_name}`,
          });
          this.setHasNewSupportMessages(true);
        }

        const { answer } = e;
        if (
          ((this.platform === 'intl' && answer.country_id !== SV_COUNTRY_ID) ||
            (this.platform === 'sv' && answer.country_id === SV_COUNTRY_ID)) &&
          answer.user_id !== this.user.id
        ) {
          // Fix for user not getting message multiple times (both when submitting and from pusher event)
          this.addNewAnswer(answer);
        }
      });
    },
    listenToTicketChannel() {
      console.log('started listening to ticket channel');
      Echo.private('ticket').listen('.new.supportTicket', e => {
        // this.$notify({
        //   group: 'primary',
        //   title: 'Success!',
        //   text: `New support ticket from ${e.ticket.user.display_name}`,
        // });

        if (
          (this.platform === 'intl' && e.country_id !== SV_COUNTRY_ID) ||
          (this.platform === 'sv' && e.country_id === SV_COUNTRY_ID)
        ) {
          SupportApi.getSupportTicket(e.ticket_id).then(res => {
            const ticket = res.data.support;
            ticket.new = true;
            this.addTicket(ticket);
          });
        }
      });
    },
    checkIfUnreadAnswers(ticketList) {
      return !!ticketList.find(ticket =>
        ticket.answers.some(
          answer => !answer.read && answer.user_id !== this.user.id
        )
      );
    },
    async getTickets() {
      if (this.$can('manage', 'Veterinarian')) {
        try {
          const pageUrl = null;
          const { data } = await this.fetchTickets(pageUrl);
          // Check all answers (but don't save in list for now) to see if there are unread answers
          this.setHasNewSupportMessages(this.checkIfUnreadAnswers(data.data));
          this.loading = false;
        } catch (e) {
          this.loading = false;
          console.log({ e });
        }
      }
    },
  },
};
</script>
